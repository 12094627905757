import axios from 'axios';
import { $append, $el, $find, $findAll, $qs, $setHTML, $trigger } from 'fxdom/es';
import { each, go, map, min, pick, pluck, sel, some, sum, tap } from 'fxjs/es';
import { AdScriptCriteoMpF } from '../../../../../modules/AdScript/Criteo/Mp/F/Function/module/AdScriptCriteoMpF.js';
import { AdScriptNaverF } from '../../../../../modules/AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import {
  CREATOR_CART_FRAME,
  CREATOR_CART_PAGE,
  CREATOR_CART_TAB,
} from '../../../../../modules/Creator/Cart/S/constant.js';
import { CreatorCriteoF } from '../../../../../modules/Creator/Criteo/F/Function/module/CreatorCriteoF.js';
import { GoodsTypeS } from '../../../../../modules/GoodsType/S/Function/module/GoodsTypeS.js';
import { resetUserCounts, setUpCSQtyNPrices } from '../../../../../modules/MPay/Cart/F/fs.js';
import {
  makeCartHtml,
  makeCartModalHtml,
  makeCartModalProductPriceContentHtml,
  makeCartModalSummaryContentHtml,
  makeWishHtml,
} from '../../../../../modules/MPay/Cart/S/tmpl.js';
import { MPayLegacyCartF } from '../../../../../modules/MPayLegacy/Cart/F/Function/module/MPayLegacyCartF.js';
import { MShopAppCartF } from '../../../../../modules/MShop/App/Cart/F/Function/module/MShopAppCartF.js';
import { MShopAppCartTmplS } from '../../../../../modules/MShop/App/Cart/S/Tmpl/module/MShopAppCartTmplS.js';
import { messages } from '../../../../../modules/MShop/App/ProductDetail/S/message_id.js';
import { MShopUtilF } from '../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { OMPCoreUtilF } from '../../../../../modules/OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { PBErrorConstantS } from '../../../../../modules/PB/Error/S/Constant/module/PBErrorConstantS.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { computeShippingCostF } from '../../../../../modules/Shipping/Cost/F/fs.js';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { BpOptionF } from '../../../../../modules/BpOption/F/Function/module/BpOptionF.js';
import { handleBizQuantityControl } from '../../../../../modules/Maker/F/Marpplizer/biz.js';

!(function (lo) {
  G.mp = G.mp || {};
  G.mp.cart = {};
  G.mp.cart.refresh_ups = function (type) {
    return function (don_tab) {
      if (window.is_test) return;
      G.change_window(
        _p.throttle(
          function () {
            $.don_loader_start();
            return _p.go(
              $.get(
                '/@api/my/' + type + '?__cart_tt__=' + Date.now(),
                box.sel('store_id') ? { store_id: box.sel('store_id') } : undefined,
              ),
              function (user_products) {
                box.set('user_products', user_products);
                // resetUserCounts();
                return user_products;
              },
              async function (user_products) {
                if (G.collabo_type === 'creator') {
                  /*마플샵에서 더이상 사용 안할것 같음*/

                  return G.mp.cart.render(
                    MShopUtilF.isMobile()
                      ? MShopAppCartTmplS.pCreatorCartMobileHtml
                      : MShopAppCartTmplS.pCreatorCartHtml,
                    user_products,
                    $.closest(don_tab, '.don_frame'),
                  );
                } else {
                  /*카트라서 도서산간비 X */
                  return G.mp.cart.render(
                    _p(type == 'cart' ? makeCartHtml : makeWishHtml, _p, computeShippingCostF),
                    user_products,
                    $.closest(don_tab, '.don_frame'),
                  );
                }
              },
              _p.noop,
              _p.wait(500),
              $.don_loader_end,
            );
          },
          1000,
          { trailing: false },
        ),
      );
    };
  };

  function swiper_init() {
    if (OMPCoreUtilF.isMobile()) return;
    const $container = $1('.product_face_container.swiper-container');
    if (!$container) return;
    _p.set(
      $container,
      'swiper',
      new Swiper($container, {
        navigation: {
          nextEl: $1('.product_preview .swiper_control.preview_next'),
          prevEl: $1('.product_preview .swiper_control.preview_prev'),
        },
      }),
    );
    const active_thumb = function () {
      const $active_slide = $1('.product_face.swiper-slide-active');
      const active_idx = $.attr($active_slide, '_idx');
      $.remove_class($('.product_face.preview_thumb'), 'active');
      const $preview_thumb = $1('.product_face.preview_thumb[_idx="' + active_idx + '"]');
      $.add_class($preview_thumb, 'active');
    };
    active_thumb();
    $container.swiper.on('slideChangeTransitionEnd', active_thumb);
  }
  G.mp.cart.swiper_init = swiper_init;

  function list_body_height_set($tab) {
    if (G.is_pc_size()) return;
    const list_body = $.find1($tab, '.list_body');
    const vh = document.documentElement.clientHeight;
    const fh = $.outerHeight($.find1($tab, '.modal_footer'));
    $.css(list_body, { 'max-height': vh - fh });
  }

  G.mp.cart.modal_showed = __(list_body_height_set, swiper_init);

  G.mp.cart.quantity_update = function (e, other_up_cs_quantity) {
    const inputs = _p.go(e.currentTarget, $.closest('.list_body'), $.find('input.quantity'));
    _p.go(
      inputs,
      _p.each(function (input) {
        const quantity = Math.abs($.val(input));
        $.val(input, Math.min(quantity, $.attr(input, 'max') || 5000));
      }),
      _p.each(function (input) {
        const quantity = Math.abs($.val(input));
        if (!_p.is_number(quantity)) return;

        const $item = $.closest(input, '.item');
        const other_quantity =
          _p.go(
            $item,
            $.closest('.list_body'),
            $.find('.item'),
            _p.reject(function ($item2) {
              return $item2 == $item;
            }),
            _p.sum(function ($item) {
              return $.val($.find1($item, 'input.quantity')) || 0;
            }),
          ) || 0;
        const up_c_s = box.sel($item);
        if (GoodsTypeS.isNotPod(up_c_s.goods_type_id)) {
          up_c_s.quantity = quantity;
          return $item;
        }
        const product_color = box.sel('maker->product_color') || box.sel('modal->product_color');

        // 수량 변경에 따른 가격 변동 사항을 DOM에 반영
        return _p.go(
          _p.mr(up_c_s, quantity, product_color, other_quantity + (other_up_cs_quantity || 0)),
          setUpCSQtyNPrices,
          _p.tap(_p(box.set, $item)),
        );
      }),
      _p.each(function (input) {
        const $item = $.closest(input, '.item');
        const $product_price = $.find1($item, '.product_price_wrap');
        $.html($product_price, makeCartModalProductPriceContentHtml(box.sel($item)));
      }),
      tap(() => {
        handleBizQuantityControl({
          pf2: box.sel('maker->product_color->product_faces2'),
          current_qty: inputs?.[0]?.value,
        });
      }),
    );
  };

  G.mp.cart.header_count_update = function (num) {
    const $value = $1('.header_title >.count >.value');
    $.text($value, $.text($value) * 1 + (num || 1));
  };

  G.mp.cart.box_data_from = function (sel) {
    return function (e) {
      return _p.go(e.currentTarget || e, $.closest(sel), box.sel);
    };
  };

  G.mp.cart.count_action = function (fn) {
    return __(
      _p.v('currentTarget'),
      $.closest('.item'),
      _p.all($.find1('.quantity'), __(box.sel, fn)),
      $.val,
      $.trigger('input'),
    );
  };

  $.frame.defn_frame({
    frame_name: collabo_type == 'creator' ? CREATOR_CART_FRAME : 'mp.cart',
    page_name: collabo_type == 'creator' ? CREATOR_CART_PAGE : 'mp.cart',
    appending: function () {
      const query = $.query_to_object(location.search);
      if (query.redirect === 'true') {
        $.alert(query.msg);
        $.frame.extend_state(void 0, null, location.origin + location.pathname, 'replace');
      }

      if (collabo_type == '' && T.lang == 'kr' && window.criteo_q) {
        AdScriptCriteoMpF.addCart(
          map((up) => {
            return {
              id: up.base_product_id,
              price: up.discounted_price,
              quantity: up.quantity,
            };
          }, box.sel('user_products')),
        );
      }

      if (collabo_type == 'creator' && T.lang == 'kr' && window.criteo_q) {
        CreatorCriteoF.addCart(
          map((up) => {
            return {
              id: sel('_.up_cs.0._.product_color._.stores_product.original_product_id', up),
              price: up.discounted_price,
              quantity: up.quantity,
            };
          }, box.sel('user_products')),
        );
      }
    },
    showed: function () {
      return MShopUtilF.isMobile() && MPayLegacyCartF.afterEdit();
    },
  });

  $.frame.defn_page({
    page_name: collabo_type == 'creator' ? CREATOR_CART_PAGE : 'mp.cart',
    tabs: [
      {
        tab_name: collabo_type == 'creator' ? CREATOR_CART_TAB : 'mp.cart',
        appended: MPayLegacyCartF.cartTabAppended,
      },
    ],
    appended: MPayLegacyCartF.cartPageAppended,
  });

  $.frame.defn_frame({
    frame_name: 'mp.cart.modal',
    page_name: 'mp.cart.modal.page',
    el_class: 'cart_modal has_up_modal_style',
    hide_frame_button_type: G.collabo_type === 'creator' ? 'x' : '',
    ...(G.collabo_type === 'creator' ? { header_height: MShopUtilF.isMobile() ? 84 : 98 } : {}),
    loader: false,
    always_remove: true,
    is_modal: G.collabo_type === 'creator' ? !MShopUtilF.isMobile() : G.is_pc_size(),
    appending: __(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) $.frame.close();
      }),
    ),
    closing: function (frame_el) {
      return frame_el.frame_opt.prev_frame_show && MPayLegacyCartF.afterEdit();
    },
  });

  function overflow_size_modal_check(el_parent) {
    const product_color = box.sel('maker->product_color') || box.sel('modal->product_color');
    const items = $.find(el_parent, '.list_body .item');
    _go(product_color.overflow_sizes, function (overflow_sizes_ids) {
      if (!overflow_sizes_ids || overflow_sizes_ids.length == 0) return;
      if ($.attr($1('html'), 'mp_worker_policy')) return;
      _go(
        items,
        _p.filter(function (item) {
          return _p.contains(overflow_sizes_ids, box.sel(item).base_product_size_id);
        }),
        $.add_class('is_overflowed'),
        $.find('input.quantity'),
        $.attr({ disabled: true }),
      );
    });
    _p.each(items, function (el_item) {
      if (!box.sel(el_item)._is_not_stock && !box.sel(el_item)._is_discontinued) return;
      _p.go(
        el_item,
        _p.tap($.find1('input.quantity'), $.attr({ disabled: true }), $.val(0), $.trigger('input')),
        $.remove_class('is_overflowed'),
        $.add_class('is_not_stock'),
      );

      if (box.sel(el_item)._is_discontinued) {
        _p.go(el_item, $.add_class('is_discontinued'));
      }
    });
  }
  G.mp.cart.overflow_size_modal_check = overflow_size_modal_check;
  const getUpcQuantity = () => sum(pluck('quantity', box.sel('modal->up_c_ss')));
  const getMaxPurchasePerUser = () => {
    if (box.sel('modal->product_color->_->stores_product->_->store->user_id') == box.sel('is_user->id')) {
      return false;
    }
    return box.sel(
      'modal->product_color->_->stores_product->_->original_stores_product->max_purchase_per_user',
    );
  };
  const getPossibleQuantityPerUser = () => box.sel('modal->up_c->possible_quantity');
  // #2 수정(edit)을 위한 모달 페이지
  $.frame.defn_page({
    page_name: 'mp.cart.modal.page',
    hide_frame_button_type: G.collabo_type === 'creator' ? '' : 'X',
    tabs: [
      {
        tab_name: 'mp.cart.modal.tab',
        template: (data) => {
          const max_purchase_per_user = getMaxPurchasePerUser();
          const possible_quantity = max_purchase_per_user
            ? Math.max(getUpcQuantity() + getPossibleQuantityPerUser(), 0)
            : null;
          return makeCartModalHtml({ ...data, max_purchase_per_user, possible_quantity });
        },
        inner_scroll_target: '.list_body',
        is_top_auto: true,
        showed: __(
          tap(function (tab_el) {
            if (G.collabo_type === 'creator') {
              const item_els = $findAll('.item', tab_el);
              go(
                item_els,
                each((el_item) => go(el_item, $.find1('input.quantity'), $.trigger('input'))),
              );
              // const size_title_els = $findAll('.size_info span', tab_el);
              // const width = max(map($width, size_title_els));
              // each($setCss({ width }), size_title_els);
              const price_key = 'price' + G._en;
              const min_price = min(map(sel(price_key), map(box.sel, item_els)));
              each((el) => {
                const item = box.sel(el);
                const diff = item[price_key] - min_price;
                if (diff > 0) {
                  $append($el(`<em>+${UtilS.commify(diff)}</em>`), $find('.size_info', el));
                }
              }, item_els);
            }
          }),
          G.mp.cart.modal_showed,
        ),
        appended: (tab_el) => {
          const already_quantity = getUpcQuantity();
          return _p.go(
            tab_el,
            // #2-1 프리뷰에 썸네일 클릭했을 때
            $.on('click', '.product_face_list.bottom .preview_thumb', function (e) {
              const swiper = $1('.product_face_container.swiper-container').swiper;
              const idx = $.attr(e.currentTarget, '_idx');
              swiper.slideTo(idx);
            }),
            $.on('change', '.mp-select-product-quantity select', BpOptionF.selectQuantityEvent),

            // #2-2 수량 조정하는 부분
            $.on(
              'input',
              '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap input.quantity',
              __(
                function (e) {
                  const up_c_s = box.sel('modal->up_c_ss->0');
                  const up = _p.find(box.sel('user_products'), function (up) {
                    return _p.find(up._.up_cs, function (up_c) {
                      return up_c.id == up_c_s.up_c_id;
                    });
                  });
                  return _p.mr(
                    e,
                    _p.go(
                      up._.up_cs,
                      _p.reject(function (up_c) {
                        return up_c.id == up_c_s.up_c_id;
                      }),
                      _p.map(function (up_c) {
                        return up_c._.up_c_ss;
                      }),
                      _p.flatten,
                      _p.pluck('quantity'),
                      _p.sum,
                    ),
                  );
                },
                G.mp.cart.quantity_update,
                _p.c('modal->up_c_ss'),
                box.sel,
                makeCartModalSummaryContentHtml,
                $.html_to('.modal .summary'),
                function () {
                  if (G.collabo_type === 'creator') {
                    const up_c_ss = box.sel('modal->up_c_ss');
                    const has_quantity = go(
                      up_c_ss,
                      some((up_c_s) => up_c_s.quantity),
                    );
                    const price = has_quantity ? up_c_ss[0]['discounted_price' + G._en] : 0;
                    $setHTML(PriceS.pricify(price), $qs('.don_frame.cart_modal .creator_price'));
                  }
                },
              ),
            ),
            $.on(
              'focusout',
              '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap input.quantity',
              function ({ currentTarget: ct }) {
                if (!box.sel('modal')) return;
                $trigger('input', ct);
              },
            ),
            $.on(
              'click',
              '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap .minus',
              G.mp.cart.count_action(function (up_c_s) {
                return up_c_s.quantity > 0 ? (up_c_s.quantity -= 1) : up_c_s.quantity;
              }),
            ),
            $.on(
              'click',
              '.item:not(.is_overflowed):not(.is_not_stock) .input_wrap .plus',
              G.mp.cart.count_action(function (up_c_s) {
                let value;

                const possible_quantity = getPossibleQuantityPerUser() + already_quantity;
                if (getMaxPurchasePerUser() && possible_quantity < getUpcQuantity() + 1) {
                  value = up_c_s.quantity;
                } else {
                  value = up_c_s.quantity < 5000 ? (up_c_s.quantity += 1) : up_c_s.quantity;
                }
                return value;
              }),
            ),
            // #2-3 변경 후 확인 버튼 누를 때
            $.on2('click', '#confirm', async function () {
              const up_c_ss = box.sel('modal->up_c_ss');
              const sum_quantity = _p.sum(function (up_c_s) {
                return up_c_s.quantity;
              });

              const prev_product_color_id = up_c_ss[0].product_color_id;

              if (sum_quantity(up_c_ss) === 0) return $.alert(T('Please select one or more products.'));
              const possible_quantity = getPossibleQuantityPerUser() + already_quantity;
              if (getMaxPurchasePerUser() && possible_quantity < getUpcQuantity()) {
                return $.alert(T(messages.possible_quantity, { possible_quantity }));
              }

              if (GoodsTypeS.isNotPod(up_c_ss[0].goods_type_id)) {
                $.don_loader_start();
                try {
                  const { user_products } = await go(
                    up_c_ss,
                    map(pick(['id', 'quantity', 'spo_item_id', 'user_id', 'product_color_id'])),
                    (user_products) =>
                      axios.post(`/${T.lang}/@api/pb_up_c_ss/cart_update`, { user_products }),
                    sel('data'),
                  );
                  box.set('modal->_user_products_', user_products);
                  resetUserCounts();
                } catch (e) {
                  const err = PBErrorConstantS.findError(e.response?.data?.message);
                  if (err) {
                    $.alert(err.text);
                  } else {
                    if (e.response?.data?.message) {
                      $.alert(e.response?.data?.message);
                    }
                    console.error(e);
                  }
                }
                $.don_loader_end();
                $.frame.close();
                return;
              }
              await _p.go(
                up_c_ss,
                _p.filter(function (up_c_s) {
                  return up_c_s.id || up_c_s.quantity;
                }),
                _p.map(function (up_c_s) {
                  // 수량이 없다는 건, 제품이 없어진다는 것
                  if (up_c_s.quantity === 0) up_c_s.is_hidden = true;
                  return _p.omit(up_c_s, ['color', 'size', 'no']);
                }),
                // #2-4 제품 업데이트
                _p($.post_load2, '/@api/up_c_ss/update'),
                async function (user_products) {
                  if (G.collabo_type === '' && user_products?.message) {
                    await $.alert(user_products?.message);
                    return;
                  }
                  if (!user_products.length) {
                    await $.alert(T('Resizing failed. Please try again.'));
                    location.reload();
                    return;
                  }
                  const up_c = _p.go(
                    user_products,
                    _map(function (up) {
                      return up._.up_cs;
                    }),
                    _p.flatten,
                    _find(function (up_c) {
                      return up_c.product_color_id == prev_product_color_id;
                    }),
                  );

                  const init_quantity = box.sel('modal->init_quantity');
                  const quantity = sum_quantity(_p.v(up_c, '_.up_c_ss'));

                  try {
                    if (typeof gtag == 'function' && quantity - init_quantity > 0) {
                      gtag('event', 'add_to_cart', {
                        dynx_itemid: [_p.v(up_c, '_.product_color._.base_product.id')], // 장바구니 담은 상품의 아이디 배열
                        dynx_itemid2: [_p.v(up_c, '_.product_color._.base_product.id')], // 장바구니 담은 상품의 아이디 배열
                        dynx_pagetype: 'conversionintent',
                        ecomm_prodid: [_p.v(up_c, '_.product_color._.base_product.id')],
                        ecomm_pagetype: 'conversionintent',
                        dynx_totalvalue: PriceS.mult(quantity - init_quantity, up_c['price' + _en]), // 장바구니 담은 상품 총 금액
                        items: [
                          {
                            id: _p.v(up_c, '_.product_color._.base_product.id'),
                            name: _p.v(up_c, '_.product_color._.base_product.name' + _en),
                            list_name: '장바구니',
                            item_category: sel('_.product_color._.base_product._.cate_list.name', up_c),
                            item_category2: sel('_.product_color._.base_product._.cate_item.name', up_c),
                            // "brand": "Google",
                            // "category": "Apparel/T-Shirts",
                            // "variant": "Black",
                            list_position: 1,
                            quantity: quantity - init_quantity,
                            price: up_c['price' + _en],
                          },
                        ],
                      });
                    } else if (typeof gtag == 'function' && quantity - init_quantity < 0) {
                      gtag('event', 'remove_from_cart', {
                        items: [
                          {
                            id: _p.v(up_c, '_.product_color._.base_product.id'),
                            name: _p.v(up_c, '_.product_color._.base_product.name' + _en),
                            list_name: '장바구니',
                            // "brand": "Google",
                            // "category": "Apparel/T-Shirts",
                            // "variant": "Black",
                            list_position: 1,
                            quantity: (quantity - init_quantity) * -1,
                            price: _p.v(up_c, 'price' + _en),
                          },
                        ],
                      });
                    }

                    if (typeof fbq == 'function' && quantity - init_quantity > 0) {
                      fbq('track', 'AddToCart', {
                        value: _sum(_p.v(up_c, '_.up_c_ss'), function (up_c_s) {
                          return PriceS.mult(up_c_s.quantity, up_c_s['price' + _en]);
                        }),
                        currency: T.lang == 'kr' ? 'KRW' : 'USD',
                        // content_ids: _.v(up_c, '_.product_color._.base_product.id'),
                        content_type: 'product',
                        contents: [
                          {
                            id: _p.v(up_c, '_.product_color._.base_product.id'),
                            name: _p.v(up_c, '_.product_color._.base_product.name'),
                            price: _p.v(up_c, '_.product_color.price' + _en),
                            quantity: _sum(_p.v(up_c, '_.up_c_ss'), function (up_c_s) {
                              return up_c_s.quantity;
                            }),
                          },
                        ],
                      });
                    }
                  } catch (err) {}

                  box.set('modal->_user_products_', user_products);
                  $.frame.close();
                  resetUserCounts();
                },
              );
            }),
            $.on('click', '#cancel', lo.close_modal),
            function (el_parent) {
              // #2-1 변경된 수량을 파악하기 위해 초기 수량값 저장
              box.set(
                'modal->init_quantity',
                _p.sum(box.sel('modal->up_c_ss'), function (up_c_s) {
                  return up_c_s.quantity;
                }),
              );
              _go(el_parent, $.find('.product_face_list'), G.mp.maker.draw_product_face6);
              G.mp.cart.overflow_size_modal_check(el_parent);
            },
          );
        },
      },
    ],
  });

  G.mp.cart.render = function (template, user_products, el_don_frame) {
    el_don_frame = el_don_frame || $1('.don_frame[frame_index="0"]');
    return _p.go(
      user_products,
      template,
      function (el_cart_page) {
        if (collabo_type == 'creator') {
          const el_store_body = $.find1(
            el_don_frame,
            '.don_tab >.don_wrapper .store-body, .don_tab >.don_wrapper .creator-layout__body',
          );
          $.html(el_store_body, el_cart_page);
          return el_store_body;
        } else {
          const don_wrapper = $.find1(el_don_frame, '.don_tab >.don_wrapper');
          const footer = $.find1(don_wrapper, '#footer');
          $.html(don_wrapper, el_cart_page);
          return $.append(don_wrapper, footer);
        }
      },
      tap((el) => {
        if (collabo_type == 'creator') {
          MShopAppCartF.afterRender(el);
        }
      }),
      $.find('.up_c_item'),
      _p.each(function (e) {
        G.mp.maker.draw_product_face2(e, _p.v(box.sel(e), '_.product_color'));
      }),
    );
  };
})({});
